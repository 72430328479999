import { Controller } from "@hotwired/stimulus"
import axios from 'axios';
import NeoModal from "src/NeoModal";

export default class extends Controller {
  update(e) {
    const url = e.target.dataset.url;

    axios.get(url).then(response => {
      const modal = NeoModal.add(response.data);

      modal.addEventListener('neo_modal:payment_success', (e) => {
        $(modal).modal('hide');

        window.loadedTabs = [];
        window.loadOrderTab($("a[data-target='#payments_info']"), true);
        window.reloadOrderHeader();
      })

      modal.addEventListener('neo_modal:failed', (e) => {
        const errors = e.detail.data.errors;
        Helper.flash_message('error', errors.base);
      })
    });
  }
}
