import axios from 'axios';

(function() {
  $(function() {
    var $new_message_conversation_modal, anchor, hash, showActionModal, showMessageModal;

    if ($('.admin_orders_show, .account_orders_show, .admin_online_members_orders_show, .account_online_members_orders_show').length > 0) {
      window.loadedOrderTabs = ['#order_info'];

      window.loadOrderTab = function(el, reload, disableScroll) {
        el.tab('show');
        let url = el.attr('href');
        const target = el.attr('data-target');

        function ajaxLoad() {
          url = url + '?target=' + target.substring(1)
          if (disableScroll) { url = url + '&do_not_scroll=true' }

          $.ajax({
            url: url,
            beforeSend: () => $(target).empty()
          }).done((response) => {
            $(target).html(response);
          }).fail(() => {
            $(target).html($("<div/>", { class: 'alert alert-danger' }).text("Something went wrong"))
          });
        }

        ajaxLoad();
      };

      window.reloadOrderHeader = function() {
        const header = $('#order_header');

        axios
          .get(header.data('url'))
          .then(response => header.replaceWith(response.data))
          .catch((e) => console.log(e))
      };

      $('.nav-tab-ajax a[data-toggle="tabajax"]').on('click', function(e) {
        e.preventDefault();
        return window.loadOrderTab($(this));
      });

      anchor = window.location.hash;
      if (anchor) {
        $("[data-target='" + anchor + "']").triggerHandler('click');
      }
    }

    $(document).on('click', '.disabled_continue', function(e) {
      e.preventDefault();
      return alert("You have chosen slots that are unavailable. Please amend before continuing.");
    });

    $(document).on('click', '.disabled_link_continue', function(e) {
      e.preventDefault();
      return false;
    });

    $('.booking-import-file').on('change', function() {
      return $('.submit-booking-import').attr('disabled', false);
    });

    showActionModal = function(link) {
      $.get(link, function(data) {
        $('#admin_orders_modal').find('.modal-content').empty().append(data);
        return $('#admin_orders_modal').modal('show');
      });
    };

    $new_message_conversation_modal = $('#new_message_conversation_modal');

    showMessageModal = function(link) {
      $.ajax({
        type: "GET",
        url: link,
        async: true,
        success: function(data) {
          $new_message_conversation_modal.find('.modal-content').empty().append(data);
          return $new_message_conversation_modal.modal('show');
        },
        error: function(data) {
          if (data.status === 422) {
            $new_message_conversation_modal.find('.modal-content').empty().append(data.responseText);
            return $new_message_conversation_modal.modal('show');
          } else {

          }
        }
      });
    };

    $(document).on('click', 'a#new_message', function(e) {
      e.preventDefault();
      return showMessageModal($(this).attr('href'));
    });

    $('.payment-failed-notified').click(function() {
      window.location.reload();
    });
  });

  window.submitFormCloseModalRefreshPage = function(button) {
    var $admin_modal_info_modal, form;
    form = $(button).closest('form');
    form.submit();
    $admin_modal_info_modal = $('#modal_info');
    $admin_modal_info_modal.find('.modal-content').empty();
    $admin_modal_info_modal.modal('close');
    $('#admin_orders_modal').modal('close');
  };

  window.reloadPageOnBackOnMobile = function() {
    if (/iphone|ipod|ipad.*os 5/gi.test(navigator.appVersion)) {
      return window.onpageshow = function(evt) {
        if (evt.persisted) {
          location.reload();
        }
      };
    }
  };
}).call(this);