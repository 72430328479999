import { Controller } from '@hotwired/stimulus'

const LOCALE = 'en-GB';
const LOCALE_OPTIONS = {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  timeZone: 'Europe/London'
}

export default class BookingCustomMessagesController extends Controller {
  static targets = ['startTime', 'endTime', 'showCheckbox', 'timeRangeInputs'];

  connect() {
    this.showCheckboxTarget.addEventListener('click', (event) => {
      if (event.target.checked) {
        const modal = $('#admin_conversations_modal');
        const startDateEl = modal.find('#booking_custom_message_start_date')
        const endDateEl = modal.find('#booking_custom_message_end_date')
        const startHourEl = modal.find('#booking_custom_message_start_hour')
        const endHourEl = modal.find('#booking_custom_message_end_hour')
        const scheduleToggleEl = modal.find('#booking_custom_message_schedule_toggle')


        const currentStartDate = startDateEl.datepicker('getDate');
        const currentEndDate = endDateEl.datepicker('getDate');

        if (!currentStartDate && !currentEndDate) {
          const startDate = new Date().toLocaleDateString(LOCALE, LOCALE_OPTIONS);
          const endDate = new Date().toLocaleDateString(LOCALE, LOCALE_OPTIONS);
          const startHour = '00:00';
          const endHour = '00:00';

          startDateEl.datepicker('setDate', startDate)
          endDateEl.datepicker('setDate', endDate)
          startHourEl.val(startHour)
          endHourEl.val(endHour)
          scheduleToggleEl.prop('checked', true)
        }

        this.timeRangeInputsTarget.hidden = false;
      } else {
        this.timeRangeInputsTarget.hidden = true;
      }
    })
  }
}
