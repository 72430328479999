import { processingModal } from '../helpers/processing_modal';
import axios from "axios";

(function() {
  $(function() {
    var $new_message_conversation_modal,
        clickOnFlag,
        clickOnIsRead,
        flagFormDate,
        getParam,
        initializeOnFlagClick,
        initializeOnIsReadClick,
        lastRecipintsUrl,
        processMessages,
        showLastRecipientsConversations,
        showModal;

    lastRecipintsUrl = '';

    processMessages = function(data) {
      $('#messages').empty().append(data);
      $('img[alt="ViewBooking"]').hide();
    };

    getParam = function(name) {
      if (name = new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)').exec(location.search)) {
        return decodeURIComponent(name[1]);
      }
    };

    if (getParam('rec_id')) {
      $.ajax({
        url: '/conversations/venue/' + getParam('venue_id') + '/user/' + getParam('rec_id'),
        data: {},
        success: function(data) {
          return processMessages(data);
        }
      });
    } else {
      $(document).ready(function() {
        $('.inbox_conversation_item a').first().trigger('click');
      });
    }

    flagFormDate = function() {
      var form, params;
      form = $('#new_filter');
      params = form.serialize();
      return params;
    };

    showLastRecipientsConversations = function(resetPagination = false) {
      let requestUrl = lastRecipintsUrl;

      if (resetPagination) {
        let [path, query] = lastRecipintsUrl.split('?');
        const params = new URLSearchParams(query);
        
        if(params.has('page')) {
          params.set('page', 1);
          requestUrl = path + "?" + params.toString();
        }
      }

      return $.ajax({
        url: requestUrl,
        data: flagFormDate(),
        success: function(data) {
          $('#recipients').empty().append(data);

          $('#recipients').find('.pair_conversation_item a').first().trigger('click');
          if ($('body').innerWidth() < 720) {
            $('.link-to-message-form').click(function() {
              $('html,body').animate({
                scrollTop: $('.right-column').offset().top
              }, 'slow');
            });
          }

          $('#messages').empty();
          initializeOnFlagClick();
          initializeOnIsReadClick();
        }
      });
    };

    const changeFlaggedFilter = (e) => {
      showLastRecipientsConversations(true);
    };

    $('input[name="filter[thread_type]"]').on('click', changeFlaggedFilter);

    clickOnFlag = function(e) {
      var flag_element;
      flag_element = $(this);

      $.ajax({
        type: "POST",
        dataType: 'json',
        url: flag_element.data('url'),
        success: function(data) {
          if (data.flagged) {
            flag_element.removeClass('not_flagged').addClass('flagged');
            flag_element.attr('title', 'Unflag thread');
          } else {
            flag_element.removeClass('flagged').addClass('not_flagged');
            flag_element.attr('title', 'Flag thread');
          }
        },
        error: () => {}
      });
    };

    initializeOnFlagClick = function() {
      $('.flag').click(clickOnFlag);
    };

    initializeOnFlagClick();

    clickOnIsRead = function(e) {
      var is_read_element;
      is_read_element = $(this);
      return $.ajax({
        type: "POST",
        dataType: 'json',
        url: is_read_element.data('url') + '&unread=' + is_read_element.hasClass('unread'),
        success: function(data) {
          if (data.unread) {
            is_read_element.removeClass('read').addClass('unread');
            is_read_element.parent().children('a').addClass('unread');
            is_read_element.attr('title', 'Mark as read');
          } else {
            is_read_element.removeClass('unread').addClass('read');
            is_read_element.parent().children('a').removeClass('unread');
            is_read_element.attr('title', 'Mark as unread');
          }
          return window.updateConversationsCounter(data);
        },
        error: function(data) {}
      });
    };
    initializeOnIsReadClick = function() {
      return $('.is_read').click(clickOnIsRead);
    };
    initializeOnIsReadClick();


    $(document).on('click', '#conversations a.recipients', function(e) {
      e.preventDefault();
      lastRecipintsUrl = $(this).attr('href');
      return showLastRecipientsConversations();
    });

    $(document).on('click', '#conversations a.conversation', function(e) {
      e.preventDefault();

      $.ajax({
        url: $(this).attr('href'),
        data: {},
        success: function(data) {
          return processMessages(data);
        }
      });
    });

    $(document).on('click', '#conversations a.venue_user_conversation', function(e) {
      e.preventDefault();

      $.ajax({
        url: $(this).attr('href'),
        data: {},
        success: function(data) {
          return processMessages(data);
        }
      });
    });

    $(document).on('click', '#conversation_send_message', function(e) {
      var $btn, form, self;
      e.preventDefault();
      $btn = $('#conversation_send_message');
      if ($btn.attr('disabled') !== 'disabled') {
        $btn.attr('disabled', true);
        self = $(this);
        form = self.closest('form');
        form.find("input[type=file]").each(function() {
          if ($(this).val() === "") {
            return $(this).remove();
          }
        });

        $.ajax({
          url: form.attr('action'),
          type: 'POST',
          data: new FormData($(form)[0]),
          async: false,
          cache: false,
          contentType: false,
          processData: false,
          beforeSend: function() {
            return processingModal.show(500);
          },
          success: function(data) {
            return $.ajax({
              url: $('#send_message_form_url').data('url'),
              success: function(data) {
                $btn.attr('disabled', false);
                processingModal.hide();
                return processMessages(data);
              }
            });
          },
          error: function(data) {
            processingModal.hide();
            $btn.attr('disabled', false);
            return $('#send_message_form').replaceWith(data.responseText);
          }
        });
      }
    });

    $new_message_conversation_modal = $('#new_message_conversation_modal');

    $(document).on('click', 'a#new_message', function(e) {
      e.preventDefault();
      showModal($(this).attr('href'));
    });

    $(document).on('click', 'a.order_action_modal', function(e) {
      e.preventDefault();
      showModal($(this).attr('href'));
    });

    $(document).on('click', '#new_message_conversation_modal form [type=submit]', function(e) {
      var $form, url;
      e.preventDefault();
      $form = $(this).closest('form');
      url = $form.attr('action');

      $.ajax({
        type: "POST",
        url: url,
        data: new FormData($($form)[0]),
        async: false,
        cache: false,
        contentType: false,
        processData: false,
        success: function(data) {
          $new_message_conversation_modal.modal('hide');
        },
        error: function(data) {
          $new_message_conversation_modal.find('.modal-content').empty().append(data.responseText);
        }
      });
    });
    showModal = function(link) {
      return $.ajax({
        type: "GET",
        url: link,
        async: true,
        success: function(data) {
          $new_message_conversation_modal.find('.modal-content').empty().append(data);
          $new_message_conversation_modal.modal('show');
        },
        error: function(data) {
          if (data.status === 422) {
            $new_message_conversation_modal.find('.modal-content').empty().append(data.responseText);
            $new_message_conversation_modal.modal('show');
          } else {

          }
        }
      });
    };

    $(document).on('change', '#new_message_recipient_id', function() {
      $('#new_message_recipient_type').val($('#new_message_recipient_id option:selected').data('recipient_type'));
    });

    $(document).on('click', '.inbox_conversation_item', function(e) {
      $('.inbox_conversation_item').removeClass('selected');
      $(this).addClass('selected');

      const venueId = parseInt(this.getAttribute('id').replace('conversation_inbox_venue_', ''));
      const multiMessageButton = document.querySelector('[data-controller="multi_message_button"]');
      multiMessageButton.dataset.venueId = venueId;
    });

    $(document).on('click', '.pair_conversation_item a', function() {
      $('.pair_conversation_item').removeClass('selected');
      $(this).parent().addClass('selected');

      if ($(this).hasClass('can_mark_read')) {
        $(this).removeClass('unread');
        $(this).parent().children('.is_read').removeClass('unread').addClass('read');
      }

      $.get('/conversations/unread_inboxes', function(data) {
        return window.updateConversationsCounter(data);
      });
    });
    
    $(document).on('keyup','#filter_recipients', _.debounce((event) => {
      const url = lastRecipintsUrl.split('?')[0];

      if (url.match(/venue_recipients/)) {
        const term = `?search_term=${event.target.value}&page=1`;
      
        axios.get(url + term).then((response) => {
          $('#recipients').empty().append(response.data);
          $('#recipients').find('.pair_conversation_item a').first().trigger('click');
        })
      } else {
        $.each($('#recipients .list-group-item'), function(index, el) {
          const title = $(el).data('title')
          $(el).toggle(title.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()))
        });
      }
    }, 300));
  });

}).call(this);