import { Controller } from "@hotwired/stimulus";
import { formatDuration } from "../src/formatDuration";

export default class BillCountdownController extends Controller {

  connect() {
    this.timer = null;

    const dataset = this.element.dataset;
    this.billId = dataset.billId
    this.duration = dataset.durationSeconds || 100;
    this.text = dataset.text;
    this.redirectUrl = dataset.redirectUrl;

    const _self = this;

    this.subscription = App.cable.subscriptions.create({ channel: "BillTimerChannel", bill_id: this.billId }, {
      connected: () => {
        _self.start();
        this.subscription.send({});
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
      },

      disconnected: () => {
        _self.stop();
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
      },

      received: (data) => {
        _self.stop();
        _self.duration = parseInt(data.order_left_duration_seconds);
        _self.start();
      }
    });
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      this.subscription.send({ command: 'get_timer' });
    }
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

  start() {
    this.stop();
    this.updateText();

    this.timer = setInterval(() => {
      if (this.duration <= 0) {
        this.stop();
        window.location.href = this.redirectUrl || '/';
      } else {
        this.duration--;
        this.updateText();
      }
    }, 1000);

    return this.timer;
  }

  disableBill() {
    const disabledDiv = document.createElement('div');
    disabledDiv.classList.add('disabled');
    const parentBlock = document.getElementById('next_bill');
    parentBlock.insertBefore(disabledDiv, parentBlock.firstChild);
  }

  updateText() {
    this.element.innerHTML = this.prepareText();
    this.element.classList.remove('invisible');
  }

  prepareText() {
    let text = this.text;
    if (!text) text = 'You have #time to complete this stage of the booking process'

    return text.replace('#time', formatDuration(this.duration * 1000));
  }

  restart() {
    this.stop();
    this.start();
  }

  stop() {
    if (this.timer) clearInterval(this.timer);
  }
}

