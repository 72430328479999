import { Controller } from "@hotwired/stimulus"
import { processingModal } from "src/nextgen/helpers/processing_modal";
import axios from "axios";
import { smartRedirect } from "src/nextgen/smartRedirect";
import NeoModal from "src/NeoModal";
import OrderTab from "src/nextgen/orders/Tab";
import OrdersTable from "src/nextgen/admin/orders/table";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";

export default class extends Controller {
  static targets = ['showAddSessions', 'acceptCancellationRequestButton'];

  connect() {
    this.checkAddSessionExist()
  }

  checkAddSessionExist() {
    let button = document.getElementsByClassName('add-session-btn')
    let sessionBlock = document.getElementById('add_sessions_block')

    if (sessionBlock && button && button.length > 0) {
      if (sessionBlock.innerHTML.length === 0) {
        button[0].style.display = 'block'
      } else {
        button[0].style.display = 'none'
      }
    }
  }

  clone(e) {
    e.preventDefault();

    const button = e.target;
    const href = button.dataset.url;
    const addSessions = button.dataset.addSessions;

    button.disabled = true;
    processingModal.show();

    axios
      .post(href, { add_sessions: addSessions })
      .then((response) => {
        if (this.isOrderPage) {
          this.orderTab.reload('next_bill', () => {
            processingModal.hide();
          });
        } else {
          window.location = response.data.redirectTo;
        }
      })
      .catch((error) => {
        Helper.flash_message('error', error.response.data.error);
        processingModal.hide();
        button.disabled = false;
    });
  }

  showAddSessionsTargetConnected(target) {
    this.addSessions(target);
  }

  add_sessions(e) {
    e.preventDefault();
    this.addSessions(e.target);
  }

  send(e) {
    e.preventDefault();

    const button = e.target;
    const { url } = e.target.dataset;

    if (button.disabled) return true;
    this.disableButton(button)

    axios.get(url).then((response) => {
      NeoModal.add(response.data)
      setTimeout(() => this.enableButton(button), 200)
    }).catch((e) => {
      if (e.response.status === 405) {
        window.location.reload();
      } else {
        this.enableButton(button);
      }
    })
  }

  approve_amendment(e) {
    e.preventDefault();

    const button = e.target;
    const { url } = e.target.dataset;

    if (button.disabled) return true;
    this.disableButton(button);

    axios.get(url).then(response => {
      if (response.status === 204) {
        axios.post(url).then(() => {
          this.enableButton(button);

          this.orderTab.reload('current_bill', () => {
            processingModal.hide();
          });
        }).catch((error) => {
          Helper.flash_message('error', 'Something went wrong')
          this.enableButton(button);
        })
      } else {
        const modal= NeoModal.add(response.data, { callback: () => {
          setTimeout(() => { this.enableButton(button) }, 200)
        }});
      }
    }).catch(() => {
      this.enableButton(button);
    });
  }

  decline_amendment(e) {
    e.preventDefault();

    const button = e.target;
    const { url } = e.target.dataset;

    if (button.disabled) return true;
    button.disabled = true;

    axios.get(url).then(response => {
      const modal= NeoModal.add(response.data, { callback: () => {
          setTimeout(() => { button.disabled = false }, 200)
        } });

      modal.addEventListener('neo_modal:success', () => {
        this.orderTab.reload('next_bill', () => {
          $(modal).modal('hide');
          processingModal.hide();
        });
      })
    }).catch(() => {
      button.disabled = false;
    })
  }

  remove(e) {
    e.preventDefault;

    const button = e.target;
    const href = button.dataset.url;

    button.disabled = true;
    processingModal.show();

    axios
      .delete(href)
      .then((response) => {
        this.orderTab.reload('current_bill', () => processingModal.hide());
      })
      .catch((error) => {
        processingModal.hide();
        Helper.flash_message('error', error.response.data.error);
        button.disabled = false;
      });
  };

  rejectPendingUser(e) {
    e.preventDefault();

    const button = e.target;
    const { url } = e.target.dataset;

    if (button.disabled) return true;
    button.disabled = true;

    axios.get(url).then((response) => {
      const modal = NeoModal.add(response.data, { callback: () => {
          setTimeout(() => { button.disabled = false }, 200)
        } });

      modal.addEventListener('neo_modal:success', () => {
        this.orderTab.reload('next_bill', () => {
          $(modal).modal('hide');
          processingModal.hide();
        });
      })
    }).catch((e) => {
      if (e.response.status === 405) {
        window.location.reload();
      } else {
        button.disabled = false;
      }
    })
  }

  cancel(e) {
    e.preventDefault();

    const button = e.target;
    const { url } = e.target.dataset;

    if (button.disabled) return true;
    button.disabled = true;

    axios.get(url).then((response) => {
      const modal = NeoModal.add(response.data, { callback: () => {
        setTimeout(() => { button.disabled = false }, 200)
      }});

      modal.addEventListener('neo_modal:success', () => {
        $(modal).modal('hide');

        if (this.isOrderPage) {
          this.orderTab.reload('', () => {
            processingModal.hide();
          });
        } else {
          const orderTable = new OrdersTable();
          orderTable.reloadData(false);
        }
      })
    }).catch((e) => {
      if (e.response.status === 405) {
        window.location.reload();
      } else {
        button.disabled = false;
      }
    })
  }

  requestCancellation(e) {
    e.preventDefault();

    const button = e.target;
    const { url } = e.target.dataset;

    if (button.disabled) return true;
    button.disabled = true;

    axios.get(url).then((response) => {
      const modal = NeoModal.add(response.data, { callback: () => {
          setTimeout(() => { button.disabled = false }, 200)
        }});

      modal.addEventListener('neo_modal:success', () => {
        $(modal).modal('hide');

        if (this.isOrderPage) {
          this.orderTab.reload('', () => {
            processingModal.hide();
          });
        } else {
          const orderTable = new OrdersTable();
          orderTable.reloadData(false);
        }
      })
    }).catch((e) => {
      if (e.response.status === 405) {
        window.location.reload();
      } else {
        button.disabled = false;
      }
    })
  }

  rejectCancellationRequest(e) {
    e.preventDefault();

    const button = e.target;
    const { url } = e.target.dataset;

    if (button.disabled) return true;
    button.disabled = true;

    axios.get(url).then((response) => {
      const modal = NeoModal.add(response.data, { callback: () => {
        setTimeout(() => { button.disabled = false }, 200)
      }});

      modal.addEventListener('neo_modal:success', () => {
        this.orderTab.reload('', () => {
          $(modal).modal('hide');
          processingModal.hide();
        });
      })
    }).catch((e) => {
      if (e.response.status === 405) {
        window.location.reload();
      } else {
        button.disabled = false;
      }
    })
  }

  acceptCancellationRequestButtonTargetConnected(element) {
    if (window.location.hash.substring(1) === 'cancellation_modal') this.acceptCancellationRequest(element);
  }

  accept_cancellation_request(e) {
    e.preventDefault();
    this.acceptCancellationRequest(e.target);
  }

  acceptCancellationRequest(button) {
    const { url } = button.dataset;

    if (button.disabled) return true;
    button.disabled = true;

    axios.get(url).then((response) => {
      const modal = NeoModal.add(response.data, { callback: () => {
        setTimeout(() => { button.disabled = false }, 200)
      }});

      modal.addEventListener('neo_modal:success', () => {
        this.orderTab.reload('', () => {
          $(modal).modal('hide');
          processingModal.hide();
        });
      })
    }).catch((e) => {
      if (e.response.status === 405) {
        window.location.reload();
      } else {
        button.disabled = false;
      }
    })
  }

  showCorrectionForm() {
    $('#admin_orders_editing_modal').modal('show')
  }

  correction(event) {
    event.preventDefault();

    processingModal.show(1)
    const target = event.target
    const form = target.closest('form');
    const url = form.getAttribute('action') + '.json';
    const data = new FormData(form);

    target.setAttribute('disabled', 'disabled');

    axios
      .patch(url, data)
      .then(() => {
        window.bookingTable.reload(false, { onUpdated: () => processingModal.hide() });
      })
      .catch((error) => {
        processingModal.hide();
        target.removeAttribute('disabled');

        if (error.response?.status === 422) {
          Helper.flash_message('error', error.response.data.errors)
        } else {
          Helper.flash_message('error', 'Something went wrong')
        }
      });
  };

  continuePendingUser(e) {
    e.preventDefault();

    const { url } = e.target.dataset;

    fetch(url, { redirect: "manual"}).then((response) => {
      if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
      } else {
        return response;
      }
    }).catch((e) => {
      if (e.response.status === 405) {
        window.location.reload();
      } else {
        button.disabled = false;
      }
    });
  }

  get orderTab() {
    return new OrderTab();
  }

  get isOrderPage() {
    return document.getElementById('current_bill') !== null;
  }

  addSessions(button) {
    const href = button.dataset.url;
    const position = document.getElementById('add_sessions_block');

    if (position.innerHTML !== '') {
      position.scrollIntoView({behavior: 'smooth'});
      return;
    }

    if (button.disabled) return true;
    button.disabled = true;

    axios.get(href).then((response) => {
      document.getElementById('add_sessions_block').innerHTML = response.data;

      const el = document.getElementById('booking_form_component')

      const bookingForm = () => import("src/components/BookingForm")

      if (el) {
        const position = document.getElementById('add_sessions_block');
        setTimeout(() => position.scrollIntoView({behavior: 'smooth'}), 200);
        button.disabled = false;

        const Component = lazy(bookingForm);
        ReactDOM.render(<Suspense fallback={<div/>}><Component/></Suspense>, el);
      }
    }).catch((error) => {
      Helper.flash_message('error', 'Something went wrong');
      button.disabled = false;
    });
  }

  disableButton(button) {
    button.disabled = true;
    button.setAttribute('disabled', 'disabled');
  }

  enableButton(button) {
    button.disabled = false;
    button.removeAttribute('disabled');
  }
}
