import { Controller } from "@hotwired/stimulus";
import ReactDOM from "react-dom";
import Form from "src/components/OnlineMemberMultiMessages/Form";
import React from "react";

export default class OnlineMemberMultiMessageButtonController extends Controller {
  connect() {
    this.element.addEventListener('click', (e) => {
      const venueId = document.querySelector('#filter_venue_id').value;
      const mountPoint = document.createElement('div');

      this.element.insertAdjacentElement('beforeend', mountPoint);

      ReactDOM.render(<Form venueId={venueId}/>, mountPoint);
    })
  }
}
