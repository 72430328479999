import { Controller } from '@hotwired/stimulus'

export default class CalendarSynController extends Controller {
  static targets = ['link']

  sync(event) {
    event.preventDefault();
    
    const filters = Object.fromEntries(new FormData(this.element));
    delete filters.authenticity_token;
    delete filters.utf8;
    const queryParams = new URLSearchParams(filters).toString();

    window.location = `${this.linkTarget.href}?${queryParams}&format=json`;
  }
}
